import React from 'react';
import { Page, PodcastsInSeries } from 'components';
import { getSiteSettings } from 'utils';
import { Box } from 'design-system';

const PodcastLandingTemplate = ({ pageContext }) => {
  const siteSettings = getSiteSettings();
  const { props: podcastSettingsProps } = siteSettings.props.podcastSettings;
  const { podcastPageSeries: podcastsInSeries } = podcastSettingsProps;

  const mainContent = (
    <Box>
      <PodcastsInSeries
        pageHeader={podcastSettingsProps.pageHeader}
        podcastsInSeries={podcastsInSeries}
        podcastsInSeriesQuery={pageContext.queryData}
      />
    </Box>
  );

  return (
    <Page blocks={[mainContent]} title={podcastSettingsProps.pageHeader} />
  );
};

export default PodcastLandingTemplate;
